/* src/components/Header.scss */
.app-header {
  background-color: #702AE2;
  padding: 20px 40px;
  color: white;
  display: flex;
  align-items: center;
  gap: 20px; /* Adjust the gap between h1 and nav */
}

.app-header h1 {
  margin: 0;
  font-size: 0.8em;
  font-weight: lighter;
}

.app-header nav {
  margin-left: 0; /* Remove the auto margin */
}

.app-header nav ul {
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 0;
  padding: 0;
}

.app-header nav ul li a {
  color: white;
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s ease;
}

.app-header nav ul li a:hover {
  color: #FFD700;
}